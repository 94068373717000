import AWS from "aws-sdk";

const s3 = new AWS.S3({
    accessKeyId: 'AKIARPVJ7ID5KBM5PYAV',
    secretAccessKey: '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',
    signatureVersion: "v4",
    region: 'us-east-1',
    params: { Bucket: process.env.S3_BUCKET_NAME }, // Optional: Bucket name from environment
});

export const getSignedImageUrl = async (key) => {
    const url = s3.getSignedUrl('getObject', {
        Bucket: process.env.S3_THUMBNAIL_BUCKET, // Optional: Thumbnail bucket name
        Key: key,
        Expires: 36000,
    });

    return url;
}

export const getSignedUrl = async (key) => {
    const params = {
        Bucket: process.env.S3_BUCKET_NAME || 'floorplanbucket', // Optional: Bucket name from environment
        Key: key,
        Expires: 36000,
    };

    try {
        const url = await new Promise((resolve, reject) => {
            s3.getSignedUrl('getObject', params, (err, url) => {
                err ? reject(err) : resolve(url);
            });
        });

        return url;
    } catch (err) {
        console.error("Error generating signed URL:", err);
        return "";
    }
}

export const getBase64 = async (userId, planId) => {
    const key = `floorplan/${userId}/${planId}-thumbnail.jpeg`;

    try {
        const file = await s3.getObject({ Key: key }).promise();
        return file.Body.toString('base64');
    } catch (err) {
        console.error("Error fetching object:", err);
        return "";
    }
}